import Link from 'next/link';

const Page404 = () => {
  return (
    <div className='flex h-screen flex-col items-center justify-center gap-10 bg-white dark:bg-gray-800'>
      <div className='flex items-center justify-center'>
        <div className='border-r-2 border-black pr-20 text-[6rem]'>404</div>
        <p className='pl-20 text-3xl'>
          <Link href='/'>
            <a>Go back home</a>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Page404;
